
import firebase from "firebase/app";
import "firebase/auth";
import {
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonImg,
  actionSheetController,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { send, close } from "ionicons/icons";

export default defineComponent({
  name: "SignIn",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonImg,
  },
  setup() {
    return {};
  },

  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.formData.email, this.formData.password)
        .catch((e) => {
          alert(e.message);
        });
    },
    forgotPassword() {
      if (this.formData.email.length > 0) {
        actionSheetController
          .create({
            header: "Reset Password?",
            cssClass: "action-sheet",
            buttons: [
              {
                text: "Send",
                icon: send,
                handler: () => {
                  firebase
                    .auth()
                    .sendPasswordResetEmail(this.formData.email)
                    .finally(() => {
                      actionSheetController.dismiss();
                      toastController
                        .create({
                          message: "Password reset was sent",
                          duration: 2000,
                        })
                        .then((toast) => toast.present());
                    });
                },
              },
              {
                text: "Cancel",
                icon: close,
                role: "cancel",
              },
            ],
          })
          .then((actionsheet) => {
            actionsheet.present();
          });
      } else {
        toastController
          .create({
            message: "Please enter your email",
            duration: 2000,
          })
          .then((toast) => toast.present());
      }
    },
  },
});
