<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="logo-header">
        <ion-img src="/assets/logo.png"></ion-img>
        <h2 style="color: #000">ADMIN</h2>
      </div>
      <div class="login-container">
        <h3>Admin Login</h3>
        <form>
          <ion-input
            style="margin-top: 1em"
            autocomplete="email"
            inputmode="email"
            type="email"
            v-model="formData.email"
            class="form-control"
            placeholder="email address"
          />
          <ion-input
            type="password"
            v-model="formData.password"
            class="form-control"
            placeholder="password"
          />
          <p>
            <a href="#" @click.stop.prevent="forgotPassword">Forgot Password</a>
          </p>
          <ion-button color="primary" size="large" @click="signIn"
            >Log In</ion-button
          >
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import firebase from "firebase/app";
import "firebase/auth";
import {
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonImg,
  actionSheetController,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { send, close } from "ionicons/icons";

export default defineComponent({
  name: "SignIn",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonImg,
  },
  setup() {
    return {};
  },

  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.formData.email, this.formData.password)
        .catch((e) => {
          alert(e.message);
        });
    },
    forgotPassword() {
      if (this.formData.email.length > 0) {
        actionSheetController
          .create({
            header: "Reset Password?",
            cssClass: "action-sheet",
            buttons: [
              {
                text: "Send",
                icon: send,
                handler: () => {
                  firebase
                    .auth()
                    .sendPasswordResetEmail(this.formData.email)
                    .finally(() => {
                      actionSheetController.dismiss();
                      toastController
                        .create({
                          message: "Password reset was sent",
                          duration: 2000,
                        })
                        .then((toast) => toast.present());
                    });
                },
              },
              {
                text: "Cancel",
                icon: close,
                role: "cancel",
              },
            ],
          })
          .then((actionsheet) => {
            actionsheet.present();
          });
      } else {
        toastController
          .create({
            message: "Please enter your email",
            duration: 2000,
          })
          .then((toast) => toast.present());
      }
    },
  },
});
</script>
<style scoped>
form {
  width: 100%;
  text-align: center;
}
h3 {
  color: #fff;
}
ion-input,
ion-button {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.logo-header {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 3em;
  padding-bottom: 3em;
  background: #efeff4;
  flex-direction: column;
}
.logo-header ion-img {
  width: 50%;
  max-width: 400px;
}
ion-content {
  --background: linear-gradient(
    130deg,
    rgba(83, 168, 186, 1) 20%,
    rgba(118, 204, 222, 1) 90%
  ) !important;
  --color: #fff !important;
}
</style>